@tailwind base;
@tailwind components;
@tailwind utilities;

@import "font";
@import "color";

* {
  font-family: "Roboto", sans-serif;
}

body {
  background-color: $base-color;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.image-rotate {
  width: 35px;
  height: 35px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.text-vps {
  color: #8c85ff; 
}

.bg-vps {
  background-color: #8c85ff;
}

.bg-vps2 {
  background-color: #464563;
}

.border-vps {
  border-color: #8c85ff;
}

.border-vps2 {
  border-color: #2c2b3d;
}
